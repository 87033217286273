exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-certificado-zelocode-tsx": () => import("./../../../src/pages/certificado-zelocode.tsx" /* webpackChunkName: "component---src-pages-certificado-zelocode-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-exacta-lovers-tsx": () => import("./../../../src/pages/exacta-lovers.tsx" /* webpackChunkName: "component---src-pages-exacta-lovers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-solucoes-projetos-e-consultoria-tsx": () => import("./../../../src/pages/solucoes/projetos-e-consultoria.tsx" /* webpackChunkName: "component---src-pages-solucoes-projetos-e-consultoria-tsx" */),
  "component---src-pages-solucoes-tech-recruiter-tsx": () => import("./../../../src/pages/solucoes/tech-recruiter.tsx" /* webpackChunkName: "component---src-pages-solucoes-tech-recruiter-tsx" */),
  "component---src-pages-solucoes-times-ageis-tsx": () => import("./../../../src/pages/solucoes/times-ageis.tsx" /* webpackChunkName: "component---src-pages-solucoes-times-ageis-tsx" */)
}

